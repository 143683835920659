<template>
  <b-card>
    <b-card-title
      v-if="facilityForm.name"
      class="pt-3"
    >
      <strong>Name EN:</strong> {{ facilityForm.name.en }}
    </b-card-title>

    <b-card-sub-title
      v-if="facilityForm.name"
      class="pb-3"
    >
      <strong>Name Ar:</strong> {{ facilityForm.name.ar }}
    </b-card-sub-title>

    <b-card-text>
      <b-row>
        <b-col md="12">
          <div
            v-if="facilityForm.activity"
            class="facility-item"
          >
            <strong>Activity EN:</strong> {{ facilityForm.activity.en }}
          </div>
          <div
            v-if="facilityForm.activity"
            class="facility-item"
          >
            <strong>Activity Ar:</strong> {{ facilityForm.activity.ar }}
          </div>
          <div
            v-if="facilityForm.address"
            class="facility-item"
          >
            <strong>Address:</strong> {{ facilityForm.address }}
          </div>
          <div
            v-if="facilityForm.phone"
            class="facility-item"
          >
            <strong>Phone:</strong> {{ facilityForm.phone }}
          </div>
          <div
            v-if="facilityForm.email"
            class="facility-item"
          >
            <strong>Email:</strong> {{ facilityForm.email }}
          </div>
          <div
            v-if="facilityForm.CR_end_date"
            class="facility-item"
          >
            <strong>CR End Date:</strong> {{ facilityForm.CR_end_date }}
          </div>
          <div
            v-if="facilityForm.CR_num"
            class="facility-item"
          >
            <strong>CR Num:</strong> {{ facilityForm.CR_num }}
          </div>
          <div
            v-if="facilityForm.audit_license_expiration"
            class="facility-item"
          >
            <strong>Audit License Expiration:</strong> {{ facilityForm.audit_license_expiration }}
          </div>
          <div
            v-if="facilityForm.postal_code"
            class="facility-item"
          >
            <strong>Postal Code:</strong> {{ facilityForm.postal_code }}
          </div>
          <div
            v-if="facilityForm.survialnce"
            class="facility-item"
          >
            <strong>Survialnce:</strong> {{ facilityForm.survialnce }}
          </div>
          <div
            v-if="facilityForm.zip_code"
            class="facility-item"
          >
            <strong>Zip Code:</strong> {{ facilityForm.zip_code }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="(request, index) in facilityForm.requests_info.requests"
          v-show="facilityForm.requests_info"
          :key="index"
          md="6"
        >
          <b-card class="request-card">
            <b-card-title class="request-title">
              Request
            </b-card-title>
            <b-card-text class="request-text">
              <div
                v-if="request.due_date"
                class="request-item"
              >
                <strong>Due Date:</strong> {{ request.due_date }}
              </div>
              <div
                v-if="request.payment_status"
                class="request-item"
              >
                <strong>Payment Status:</strong> {{ request.payment_status }}
              </div>
              <div
                v-if="request.facility_name"
                class="request-item"
              >
                <strong>Facility Name:</strong> {{ request.facility_name }}
              </div>
              <div
                v-if="request.certificate"
                class="request-item"
              >
                <strong>Certificate:</strong> {{ request.certificate }}
              </div>
              <div
                v-if="request.source"
                class="request-item"
              >
                <strong>Source:</strong> {{ request.source }}
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-for="(delegator, index) in facilityForm.delegators_info"
        v-show="facilityForm.delegators_info"
        :key="index"
        class="mt-3"
      >
        <b-col md="12">
          <b-card>
            <b-card-title>Delegator</b-card-title>
            <b-card-text>
              <div
                v-show="delegator.email"
                class="facility-item"
              >
                <strong>Email:</strong> {{ delegator.email }}
              </div>
              <div
                v-show="delegator.phone"
                class="facility-item"
              >
                <strong>Phone:</strong> {{ delegator.phone }}
              </div>
              <div
                v-show="delegator.auto_proceed"
                class="facility-item"
              >
                <strong>Auto Proceed:</strong> {{ delegator.auto_proceed }}
              </div>
              <div
                v-show="delegator.wallet_balance"
                class="facility-item"
              >
                <strong>Wallet Balance:</strong> {{ delegator.wallet_balance }}
              </div>
              <div
                v-show="delegator.offer"
                class="facility-item"
              >
                <strong>Offer:</strong> {{ delegator.offer }}
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      facilityForm: [],
    }
  },
  mounted() {
    this.showfacility()
  },
  methods: {
    showfacility() {
      if (this.$route.params.id) {
        axios.get(`factory/${this.$route.params.id}`).then(res => {
          this.facilityForm = decryptData(res.data.payload).data.factory
        })
      } else {
        return false
      }
      return true
    },
  },
}
</script>

<style scoped>
.facility-item {
  margin-bottom: 10px;
}

.request-card {
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 15px;
}

.request-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.request-text {
  font-size: 1rem;
  line-height: 1.5;
}

.request-item {
  margin-bottom: 5px;
}
</style>
